export default {
  regiones: [
    {
      id: 1,
      name: 'Villa Nueva',
      description: 'Ahi asaltan',
      status: 'Activa',
      created_at: '16-01-2021',
      update_at: '',
    },
    {
      id: 2,
      name: 'San miguel petapa',
      description: 'Ahi asaltan x2',
      status: 'Activa',
      created_at: '13-05-2020',
      update_at: '',
    },
    {
      id: 3,
      name: 'Zona 16',
      description: 'fino el pana',
      status: 'Activa',
      created_at: '11-04-2020',
      update_at: '',
    },
    {
      id: 4,
      name: 'Zona 18',
      description: 'Cuidao',
      status: 'Activa',
      created_at: '01-12-2020',
      update_at: '',
    },
    {
      id: 5,
      name: 'Zona 10',
      description: 'Zona viva',
      status: 'Inactiva',
      created_at: '22-07-2020',
      update_at: '',
    },
  ],
}
