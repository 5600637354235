<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="regionEditSidebarActive"
      @change="(val) => $emit('update:region-edit-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Editar Región
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
          >
            <validation-provider
              v-slot="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                label="Región:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Ingresa el nombre"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="descripción"
            >
              <b-form-group
                label="Descripción:"
                label-for="input-description"
              >
                <b-form-input
                  id="input-description"
                  v-model="form.description"
                  placeholder="Ingresa una descripción"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="estado"
            >
              <b-form-group
                label="Estado:"
                label-for="input-status"
              >
                <b-form-select
                  id="input-status"
                  v-model="form.status"
                  :options="statusOptions"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Actualizar
            </b-button>

          </b-form>
        </validation-observer>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import regionData from './RegionData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'regionEditSidebarActive',
    event: 'update:region-edit-sidebar-active',
  },
  props: {
    regionEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    regionId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      required,

      form: {
        name: '',
        description: '',
        status: '',
      },
      show: true,

      statusOptions: [
        { text: 'Activa', value: 'Y' },
        { text: 'Inactiva', value: 'N' },
      ],
      regionInfo: regionData.regiones,
    }
  },

  watch: {
    regionEditSidebarActive(val) {
      if (val) {
        this.getRegion()
      }
    },
  },
  created() {
    // this.getCategory()
  },

  methods: {
    getRegion() {
      axios
        .get(`category/${this.regionId}`)
        .then(response => {
          this.form.name = response.data.data.name
          this.form.description = response.data.data.description
          this.form.status = response.data.data.status
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSubmit(event) {
      event.preventDefault()

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // axios
          //  .put(`region/${this.regionId}`, this.form)
          //  .then(response => {
          //    this.makeToast('success', 'Región actualizada', `Se actualizó la región a: ${response.data.data.name}.`)
          //    this.clearForm()
          //    this.$emit('update:region-edit-sidebar-active', false)
          //    this.$emit('refreshData')
          //  })
          //  .catch(error => {
          //    this.showErrors(error)
          //  })
          for (let index = 0; index < this.regionInfo.length; index += 1) {
            const element = this.regionInfo[index]
            if (element.id === this.regionId) {
              element.name = this.form.name
              element.description = this.form.description
              element.status = this.form.status
              element.update_at = '15-01-2022'
            }
          }
          this.makeToast('success', 'Región actualizada', 'Se actualizó la región exitosamente')
        } else {
          this.makeToast('danger', 'Error', 'No es posible actualizar la categoría.')
        }
      })
    },

    clearForm() {
      // Reset our form values
      this.form.name = ''
      this.form.description = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
